<template>
  <section class="section section_votes">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="title title_dark title_second title_svg title_svg-show text-uppercase">Материалы партнеров
          </h2>
        </div>
      </div>
      <div class="row pt-4 d-flex flex-column-reverse flex-lg-row">
        <div class="col-12 col-lg-3">
          <div class="pt-2 pt-lg-0">
            <rasp-slider></rasp-slider>
          </div>
        </div>
        <div class="col-12 col-lg-9">
          <div class="row d-flex align-items-stretch">
            <div class="col-12 col-md-6 col-lg-4 pb-4 d-flex align-items-stretch" v-for="partner in getPartners"
                 :key="partner.id">
              <div class="partner">
                <div class="partner__image">
                  <img :src="partner.avatar" :alt="partner.name">
                </div>
                <div class="partner__files d-flex flex-wrap pt-4">
                  <div class="partner__file pb-2" v-for="file in partner.PartnerFiles" :key="file.id">
                    <a :href="file.link" target="_blank">{{ file.name }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import RaspSlider from "../components/components/RaspSlider";

export default {
  name: "Materials",
  components: {RaspSlider},
  data() {
    return {
      partners: [{
        "id": 19,
        "name": "CNews",
        "avatar": "/images/Cnews.png",
        "link": "https://www.cnews.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 1,
        "hideToIndex": false,
        "createdAt": "2023-03-30T09:13:30.000Z",
        "updatedAt": "2023-03-30T09:13:30.000Z",
        "PartnerFiles": []
      }, {
        "id": 36,
        "name": "Треолан",
        "avatar": "/images/treolan_logo_rgb.png",
        "link": "https://www.treolan.ru/",
        "group": "Стратегические партнеры",
        "newPage": true,
        "order": 1,
        "hideToIndex": false,
        "createdAt": "2023-04-13T17:43:47.000Z",
        "updatedAt": "2023-04-17T15:39:04.000Z",
        "PartnerFiles": [{
          "id": 59,
          "link": "https://disk.yandex.ru/i/X5ybPWP6icIwjQ",
          "name": "Информация",
          "order": 20,
          "createdAt": "2023-04-21T16:12:19.000Z",
          "updatedAt": "2023-04-21T16:12:38.000Z",
          "partner_id": 36
        }]
      }, {
        "id": 17,
        "name": "Росэнергоатом",
        "avatar": "/images/росэнергоатом.png",
        "link": "https://www.rosenergoatom.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 1,
        "hideToIndex": false,
        "createdAt": "2023-03-24T13:56:35.000Z",
        "updatedAt": "2023-03-24T13:56:35.000Z",
        "PartnerFiles": []
      }, {
        "id": 29,
        "name": "Axoft",
        "avatar": "/images/Axoft.png",
        "link": "https://axoftglobal.ru/",
        "group": "Стратегические партнеры",
        "newPage": true,
        "order": 2,
        "hideToIndex": false,
        "createdAt": "2023-04-10T13:02:29.000Z",
        "updatedAt": "2023-04-10T13:02:29.000Z",
        "PartnerFiles": [{
          "id": 78,
          "link": "https://disk.yandex.ru/i/0J94EIuziJhVrA",
          "name": "Презентация",
          "order": 1,
          "createdAt": "2023-04-24T12:31:26.000Z",
          "updatedAt": "2023-04-24T12:31:26.000Z",
          "partner_id": 29
        }, {
          "id": 79,
          "link": "https://disk.yandex.ru/i/y7OX8wYly-NAgQ",
          "name": "Листовка 1",
          "order": 2,
          "createdAt": "2023-04-24T12:32:43.000Z",
          "updatedAt": "2023-04-24T12:36:04.000Z",
          "partner_id": 29
        }, {
          "id": 80,
          "link": "https://disk.yandex.ru/i/-LhM54sACk3Ibg",
          "name": "Листовка 2",
          "order": 3,
          "createdAt": "2023-04-24T12:33:29.000Z",
          "updatedAt": "2023-04-24T12:36:13.000Z",
          "partner_id": 29
        }, {
          "id": 81,
          "link": "https://disk.yandex.ru/i/SUShiXkok3s0xA",
          "name": "Каталог",
          "order": 4,
          "createdAt": "2023-04-24T12:33:54.000Z",
          "updatedAt": "2023-04-24T12:33:54.000Z",
          "partner_id": 29
        }, {
          "id": 82,
          "link": "https://disk.yandex.ru/i/jJFo1rb75Jr1wg",
          "name": "Видео",
          "order": 5,
          "createdAt": "2023-04-24T12:34:29.000Z",
          "updatedAt": "2023-04-24T12:34:29.000Z",
          "partner_id": 29
        }]
      }, {
        "id": 11,
        "name": "ICT Online",
        "avatar": "/images/Group 109.png",
        "link": "https://ict-online.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 2,
        "hideToIndex": false,
        "createdAt": "2023-03-20T10:00:41.000Z",
        "updatedAt": "2023-03-22T14:30:26.000Z",
        "PartnerFiles": []
      }, {
        "id": 15,
        "name": "Infotecs",
        "avatar": "/images/infotecs_logo2.png",
        "link": "https://infotecs.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 2,
        "hideToIndex": false,
        "createdAt": "2023-03-23T11:00:38.000Z",
        "updatedAt": "2023-03-24T13:53:17.000Z",
        "PartnerFiles": [{
          "id": 1,
          "link": "https://disk.yandex.ru/i/xvgcLKbNvEwALA",
          "name": "Брошюра",
          "order": 1,
          "createdAt": "2023-03-23T11:08:11.000Z",
          "updatedAt": "2023-03-23T11:08:11.000Z",
          "partner_id": 15
        }, {
          "id": 2,
          "link": "https://disk.yandex.ru/i/BMfX1IdtF_hbdg",
          "name": "Листовка",
          "order": 2,
          "createdAt": "2023-03-23T11:08:41.000Z",
          "updatedAt": "2023-03-23T11:08:41.000Z",
          "partner_id": 15
        }, {
          "id": 5,
          "link": "https://disk.yandex.ru/i/l7SIW4lJm1H6Kw",
          "name": "Видео 1",
          "order": 3,
          "createdAt": "2023-03-31T15:19:27.000Z",
          "updatedAt": "2023-03-31T15:19:27.000Z",
          "partner_id": 15
        }, {
          "id": 6,
          "link": "https://disk.yandex.ru/i/kq1C9iA-RHW5yQ",
          "name": "Видео 2",
          "order": 4,
          "createdAt": "2023-03-31T15:20:07.000Z",
          "updatedAt": "2023-03-31T15:20:07.000Z",
          "partner_id": 15
        }]
      }, {
        "id": 32,
        "name": "Аквариус",
        "avatar": "/images/Aquarius.png",
        "link": "https://www.aq.ru/",
        "group": "Стратегические партнеры",
        "newPage": true,
        "order": 3,
        "hideToIndex": false,
        "createdAt": "2023-04-11T12:33:36.000Z",
        "updatedAt": "2023-04-11T12:33:36.000Z",
        "PartnerFiles": [{
          "id": 16,
          "link": "https://disk.yandex.ru/i/sBXHdeeEo-M5uw",
          "name": "Aquarius CMP NS685U",
          "order": 1,
          "createdAt": "2023-04-21T15:08:33.000Z",
          "updatedAt": "2023-04-21T15:08:33.000Z",
          "partner_id": 32
        }, {
          "id": 17,
          "link": "https://disk.yandex.ru/i/Xt-23CYzw6IBuA",
          "name": "Aquarius Cmp NS483",
          "order": 2,
          "createdAt": "2023-04-21T15:09:02.000Z",
          "updatedAt": "2023-04-21T15:09:02.000Z",
          "partner_id": 32
        }, {
          "id": 18,
          "link": "https://disk.yandex.ru/i/NEsQyb2oLyW31w",
          "name": "Aquarius Cmp NS483R",
          "order": 3,
          "createdAt": "2023-04-21T15:09:28.000Z",
          "updatedAt": "2023-04-21T15:09:28.000Z",
          "partner_id": 32
        }, {
          "id": 19,
          "link": "https://disk.yandex.ru/i/Ox_TY9Ytd-XlvA",
          "name": "Aquarius Mnb Pro T904",
          "order": 4,
          "createdAt": "2023-04-21T15:09:58.000Z",
          "updatedAt": "2023-04-21T15:09:58.000Z",
          "partner_id": 32
        }, {
          "id": 20,
          "link": "https://disk.yandex.ru/i/aK9Zd9MpMEX74A",
          "name": "О компании",
          "order": 5,
          "createdAt": "2023-04-21T15:10:38.000Z",
          "updatedAt": "2023-04-21T15:10:38.000Z",
          "partner_id": 32
        }, {
          "id": 21,
          "link": "https://disk.yandex.ru/i/a7udmJqhXbyBDw",
          "name": "Брошюра",
          "order": 6,
          "createdAt": "2023-04-21T15:10:57.000Z",
          "updatedAt": "2023-04-21T15:10:57.000Z",
          "partner_id": 32
        }, {
          "id": 22,
          "link": "https://disk.yandex.ru/i/uHcgpUMsft181A",
          "name": "Ролик о компании",
          "order": 7,
          "createdAt": "2023-04-21T15:13:05.000Z",
          "updatedAt": "2023-04-21T15:13:05.000Z",
          "partner_id": 32
        }]
      }, {
        "id": 13,
        "name": "IT World",
        "avatar": "/images/itworld.jpg",
        "link": "https://www.it-world.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 3,
        "hideToIndex": false,
        "createdAt": "2023-03-22T13:57:33.000Z",
        "updatedAt": "2023-03-22T14:03:06.000Z",
        "PartnerFiles": []
      }, {
        "id": 42,
        "name": "Delta",
        "avatar": "/images/delta.png",
        "link": "https://deltacomputers.ru/",
        "group": "Стратегические партнеры",
        "newPage": true,
        "order": 4,
        "hideToIndex": false,
        "createdAt": "2023-04-14T17:08:42.000Z",
        "updatedAt": "2023-04-17T15:39:12.000Z",
        "PartnerFiles": []
      }, {
        "id": 16,
        "name": "TrueConf",
        "avatar": "/images/infotecs_logo.png",
        "link": "https://trueconf.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 4,
        "hideToIndex": false,
        "createdAt": "2023-03-24T13:52:29.000Z",
        "updatedAt": "2023-03-24T13:52:29.000Z",
        "PartnerFiles": [{
          "id": 15,
          "link": "https://disk.yandex.ru/i/xY1BMpck0Z8YrA",
          "name": "Видео",
          "order": 1,
          "createdAt": "2023-04-12T16:53:39.000Z",
          "updatedAt": "2023-04-12T16:53:39.000Z",
          "partner_id": 16
        }, {
          "id": 73,
          "link": "https://disk.yandex.ru/i/7PUmytkaCDAkdQ",
          "name": "Буклет",
          "order": 2,
          "createdAt": "2023-04-24T11:33:08.000Z",
          "updatedAt": "2023-04-24T11:33:08.000Z",
          "partner_id": 16
        }]
      }, {
        "id": 14,
        "name": "OCS Distribution",
        "avatar": "/images/rupost (1).png",
        "link": "https://www.ocs.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 5,
        "hideToIndex": false,
        "createdAt": "2023-03-23T10:57:12.000Z",
        "updatedAt": "2023-03-23T10:57:12.000Z",
        "PartnerFiles": [{
          "id": 9,
          "link": "https://disk.yandex.ru/i/SSUD1sl5u6O3Xw",
          "name": "Буклет",
          "order": 1,
          "createdAt": "2023-04-10T14:58:36.000Z",
          "updatedAt": "2023-04-10T14:58:36.000Z",
          "partner_id": 14
        }]
      }, {
        "id": 24,
        "name": "Открытые системы",
        "avatar": "/images/открытые системы.png",
        "link": "https://www.osp.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 5,
        "hideToIndex": false,
        "createdAt": "2023-04-06T07:24:03.000Z",
        "updatedAt": "2023-04-06T07:24:03.000Z",
        "PartnerFiles": []
      }, {
        "id": 18,
        "name": "Гарант",
        "avatar": "/images/garant.png",
        "link": "http://linux.garant.ru/?utm_source=astraconf&utm_medium=affiliate&utm_campaign=conf",
        "group": "Партнеры",
        "newPage": true,
        "order": 5,
        "hideToIndex": false,
        "createdAt": "2023-03-28T13:28:04.000Z",
        "updatedAt": "2023-04-10T13:39:26.000Z",
        "PartnerFiles": [{
          "id": 11,
          "link": "https://disk.yandex.ru/i/4JMcqLD0o_K8ew",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2023-04-10T15:43:52.000Z",
          "updatedAt": "2023-04-10T15:43:52.000Z",
          "partner_id": 18
        }, {
          "id": 12,
          "link": "https://disk.yandex.ru/i/e9Wz8wR8Pb7Y9w",
          "name": "Видео",
          "order": 2,
          "createdAt": "2023-04-10T15:44:18.000Z",
          "updatedAt": "2023-04-10T15:44:18.000Z",
          "partner_id": 18
        }]
      }, {
        "id": 10,
        "name": "Global CIO",
        "avatar": "/images/Group 108.png",
        "link": "https://globalcio.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 5,
        "hideToIndex": false,
        "createdAt": "2023-03-20T09:59:27.000Z",
        "updatedAt": "2023-03-30T09:12:51.000Z",
        "PartnerFiles": []
      }, {
        "id": 9,
        "name": "ICL Техно",
        "avatar": "/images/icl2.png",
        "link": "http://icl-techno.ru",
        "group": "Стратегические партнеры",
        "newPage": true,
        "order": 5,
        "hideToIndex": false,
        "createdAt": "2023-03-20T09:57:16.000Z",
        "updatedAt": "2023-04-17T15:38:28.000Z",
        "PartnerFiles": [{
          "id": 26,
          "link": "https://disk.yandex.ru/i/X9N2U2O4Xf1epg",
          "name": "Ноутбук Raybook Bi1407",
          "order": 1,
          "createdAt": "2023-04-21T15:21:00.000Z",
          "updatedAt": "2023-04-21T15:21:00.000Z",
          "partner_id": 9
        }, {
          "id": 27,
          "link": "https://disk.yandex.ru/i/lmR8_PKNI50rbA",
          "name": "Ноутбук Raybook Pi1508",
          "order": 2,
          "createdAt": "2023-04-21T15:21:26.000Z",
          "updatedAt": "2023-04-21T15:21:26.000Z",
          "partner_id": 9
        }, {
          "id": 28,
          "link": "https://disk.yandex.ru/i/KJQ6ACnzbv3_yw",
          "name": "Сервер 2122-2U-M",
          "order": 3,
          "createdAt": "2023-04-21T15:21:51.000Z",
          "updatedAt": "2023-04-21T15:21:51.000Z",
          "partner_id": 9
        }, {
          "id": 29,
          "link": "https://disk.yandex.ru/i/udSSarjtHbYfrg",
          "name": "Телемедицинский комплекс",
          "order": 4,
          "createdAt": "2023-04-21T15:22:51.000Z",
          "updatedAt": "2023-04-21T15:22:51.000Z",
          "partner_id": 9
        }, {
          "id": 30,
          "link": "https://disk.yandex.ru/i/HSgmX6r7y7wcIQ",
          "name": "Телемедицинская стойка",
          "order": 5,
          "createdAt": "2023-04-21T15:23:21.000Z",
          "updatedAt": "2023-04-21T15:23:21.000Z",
          "partner_id": 9
        }, {
          "id": 31,
          "link": "https://disk.yandex.ru/i/YGUTuI5XfqVqQw",
          "name": "Киоск ритейл красный",
          "order": 6,
          "createdAt": "2023-04-21T15:24:04.000Z",
          "updatedAt": "2023-04-21T15:24:04.000Z",
          "partner_id": 9
        }, {
          "id": 32,
          "link": "https://disk.yandex.ru/i/tgrbXm55rbapvA",
          "name": "Киоск ритейл серый",
          "order": 7,
          "createdAt": "2023-04-21T15:24:43.000Z",
          "updatedAt": "2023-04-21T15:24:43.000Z",
          "partner_id": 9
        }, {
          "id": 33,
          "link": "https://disk.yandex.ru/i/s5z2idJu8LnkBA",
          "name": "маркетинг кит",
          "order": 8,
          "createdAt": "2023-04-21T15:25:24.000Z",
          "updatedAt": "2023-04-21T15:25:24.000Z",
          "partner_id": 9
        }, {
          "id": 34,
          "link": "https://disk.yandex.ru/i/H8dHr-7usb6h_A",
          "name": "Мониторы ICL ViewRAY",
          "order": 9,
          "createdAt": "2023-04-21T15:26:06.000Z",
          "updatedAt": "2023-04-21T15:26:06.000Z",
          "partner_id": 9
        }, {
          "id": 35,
          "link": "https://disk.yandex.ru/i/c2lKVYyoRDv3og",
          "name": "Листовка",
          "order": 10,
          "createdAt": "2023-04-21T15:27:03.000Z",
          "updatedAt": "2023-04-21T15:27:03.000Z",
          "partner_id": 9
        }, {
          "id": 36,
          "link": "https://disk.yandex.ru/i/2DuzdXHsXTvkgQ",
          "name": "Информация о компании",
          "order": 11,
          "createdAt": "2023-04-21T15:27:51.000Z",
          "updatedAt": "2023-04-21T15:27:51.000Z",
          "partner_id": 9
        }, {
          "id": 74,
          "link": "https://disk.yandex.ru/i/FCyyZ6l04otCSw",
          "name": "Видео ICL4",
          "order": 12,
          "createdAt": "2023-04-24T12:10:14.000Z",
          "updatedAt": "2023-04-24T12:10:14.000Z",
          "partner_id": 9
        }, {
          "id": 75,
          "link": "https://disk.yandex.ru/i/PyHq8fYIoBg2yw",
          "name": "Ролик ICL Techno",
          "order": 13,
          "createdAt": "2023-04-24T12:12:23.000Z",
          "updatedAt": "2023-04-24T12:12:23.000Z",
          "partner_id": 9
        }]
      }, {
        "id": 4,
        "name": "RuPost",
        "avatar": "/images/rupost.png",
        "link": "https://www.rupost.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 6,
        "hideToIndex": false,
        "createdAt": "2023-03-20T09:18:44.000Z",
        "updatedAt": "2023-03-20T10:56:02.000Z",
        "PartnerFiles": []
      }, {
        "id": 28,
        "name": "Tadviser",
        "avatar": "/images/tadviser.png",
        "link": "https://www.tadviser.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 6,
        "hideToIndex": false,
        "createdAt": "2023-04-10T12:34:55.000Z",
        "updatedAt": "2023-04-10T12:35:38.000Z",
        "PartnerFiles": []
      }, {
        "id": 43,
        "name": "Russoft",
        "avatar": "/images/russoft2.jpg",
        "link": "https://russoft.org/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 7,
        "hideToIndex": false,
        "createdAt": "2023-04-14T17:23:21.000Z",
        "updatedAt": "2023-04-14T17:23:21.000Z",
        "PartnerFiles": []
      }, {
        "id": 3,
        "name": "BILLmanager",
        "avatar": "/images/bill.png",
        "link": "https://www.ispsystem.ru",
        "group": "Партнеры",
        "newPage": true,
        "order": 7,
        "hideToIndex": false,
        "createdAt": "2023-03-20T09:14:45.000Z",
        "updatedAt": "2023-04-21T13:35:53.000Z",
        "PartnerFiles": []
      }, {
        "id": 44,
        "name": "Anti Malware",
        "avatar": "/images/Anti Malware2.png",
        "link": "https://www.anti-malware.ru",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 8,
        "hideToIndex": false,
        "createdAt": "2023-04-17T07:43:56.000Z",
        "updatedAt": "2023-04-17T07:45:33.000Z",
        "PartnerFiles": []
      }, {
        "id": 20,
        "name": "Mont",
        "avatar": "/images/mont.png",
        "link": "https://www.mont.ru/ru-ru",
        "group": "Партнеры",
        "newPage": true,
        "order": 8,
        "hideToIndex": false,
        "createdAt": "2023-03-31T14:30:50.000Z",
        "updatedAt": "2023-03-31T14:30:50.000Z",
        "PartnerFiles": [{
          "id": 3,
          "link": "https://disk.yandex.ru/i/JM9XAxM6vZteCQ",
          "name": "Презентация",
          "order": 1,
          "createdAt": "2023-03-31T15:12:45.000Z",
          "updatedAt": "2023-03-31T15:12:45.000Z",
          "partner_id": 20
        }, {
          "id": 4,
          "link": "https://disk.yandex.ru/i/C1ilMXBPumHXUg",
          "name": "Видео",
          "order": 2,
          "createdAt": "2023-03-31T15:13:04.000Z",
          "updatedAt": "2023-03-31T15:13:04.000Z",
          "partner_id": 20
        }]
      }, {
        "id": 2,
        "name": "ALD Pro",
        "avatar": "/images/ald.png",
        "link": "http://aldpro.ru",
        "group": "Партнеры",
        "newPage": true,
        "order": 9,
        "hideToIndex": false,
        "createdAt": "2023-03-17T08:17:26.000Z",
        "updatedAt": "2023-03-20T10:32:51.000Z",
        "PartnerFiles": []
      }, {
        "id": 45,
        "name": "Bis TV",
        "avatar": "/images/лого новый на прозрачной.png",
        "link": "https://ib-bank.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 9,
        "hideToIndex": false,
        "createdAt": "2023-04-17T08:12:28.000Z",
        "updatedAt": "2023-04-17T08:12:28.000Z",
        "PartnerFiles": []
      }, {
        "id": 46,
        "name": "Код информационной безопасности",
        "avatar": "/images/Код Иб.png",
        "link": "https://codeib.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 9,
        "hideToIndex": false,
        "createdAt": "2023-04-17T13:07:38.000Z",
        "updatedAt": "2023-04-17T13:07:38.000Z",
        "PartnerFiles": []
      }, {
        "id": 1,
        "name": " RuBackup",
        "avatar": "/images/rubackup.png",
        "link": "https://rubackup.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 10,
        "hideToIndex": false,
        "createdAt": "2023-03-17T08:16:28.000Z",
        "updatedAt": "2023-03-17T08:16:28.000Z",
        "PartnerFiles": []
      }, {
        "id": 53,
        "name": "Cyber Media",
        "avatar": "/images/кибермедиа.png",
        "link": "https://securitymedia.org/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 10,
        "hideToIndex": false,
        "createdAt": "2023-04-20T12:14:41.000Z",
        "updatedAt": "2023-04-20T12:18:36.000Z",
        "PartnerFiles": []
      }, {
        "id": 21,
        "name": "Tegrus",
        "avatar": "/images/tegrus.png",
        "link": "https://tegrus.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 12,
        "hideToIndex": false,
        "createdAt": "2023-03-31T15:32:50.000Z",
        "updatedAt": "2023-03-31T15:32:50.000Z",
        "PartnerFiles": [{
          "id": 7,
          "link": "https://tegrus.cnews.ru/",
          "name": "Информация",
          "order": 1,
          "createdAt": "2023-03-31T15:34:00.000Z",
          "updatedAt": "2023-03-31T15:34:00.000Z",
          "partner_id": 21
        }, {
          "id": 58,
          "link": "https://disk.yandex.ru/i/Z0ivSYAVLPBm1g",
          "name": "Видео",
          "order": 2,
          "createdAt": "2023-04-21T16:10:35.000Z",
          "updatedAt": "2023-04-21T16:10:35.000Z",
          "partner_id": 21
        }]
      }, {
        "id": 6,
        "name": "Брест",
        "avatar": "/images/brest.png",
        "link": "https://astralinux.ru/products/pk-brest/",
        "group": "Партнеры",
        "newPage": true,
        "order": 12,
        "hideToIndex": false,
        "createdAt": "2023-03-20T09:32:31.000Z",
        "updatedAt": "2023-03-20T10:33:17.000Z",
        "PartnerFiles": []
      }, {
        "id": 7,
        "name": "Termidesk",
        "avatar": "/images/termidesk.png",
        "link": "https://termidesk.ru",
        "group": "Партнеры",
        "newPage": true,
        "order": 13,
        "hideToIndex": false,
        "createdAt": "2023-03-20T09:41:01.000Z",
        "updatedAt": "2023-03-20T10:33:28.000Z",
        "PartnerFiles": []
      }, {
        "id": 8,
        "name": "Workspad",
        "avatar": "/images/workspad.png",
        "link": "https://www.workspad.ru",
        "group": "Партнеры",
        "newPage": true,
        "order": 14,
        "hideToIndex": false,
        "createdAt": "2023-03-20T09:43:23.000Z",
        "updatedAt": "2023-03-20T10:34:35.000Z",
        "PartnerFiles": []
      }, {
        "id": 12,
        "name": "Tantor",
        "avatar": "/images/tantor.png",
        "link": "https://tantorlabs.ru",
        "group": "Партнеры",
        "newPage": true,
        "order": 15,
        "hideToIndex": false,
        "createdAt": "2023-03-20T10:34:27.000Z",
        "updatedAt": "2023-03-20T10:34:27.000Z",
        "PartnerFiles": []
      }, {
        "id": 23,
        "name": "К2Тех",
        "avatar": "/images/к2 тех.png",
        "link": "https://k2.tech/",
        "group": "Партнеры",
        "newPage": true,
        "order": 16,
        "hideToIndex": false,
        "createdAt": "2023-04-05T14:24:54.000Z",
        "updatedAt": "2023-04-17T15:30:25.000Z",
        "PartnerFiles": []
      }, {
        "id": 22,
        "name": "Гравитон",
        "avatar": "/images/гравитон 4.png",
        "link": "https://graviton.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 17,
        "hideToIndex": false,
        "createdAt": "2023-04-03T14:04:51.000Z",
        "updatedAt": "2023-04-03T14:11:21.000Z",
        "PartnerFiles": [{
          "id": 8,
          "link": "https://disk.yandex.ru/i/s8J8LI8n8EGG3g",
          "name": "Презентация",
          "order": 1,
          "createdAt": "2023-04-05T17:01:14.000Z",
          "updatedAt": "2023-04-24T12:25:21.000Z",
          "partner_id": 22
        }, {
          "id": 61,
          "link": "https://disk.yandex.ru/i/yhbepbHwJJE85A",
          "name": "О компании",
          "order": 2,
          "createdAt": "2023-04-21T16:23:44.000Z",
          "updatedAt": "2023-04-24T12:25:22.000Z",
          "partner_id": 22
        }, {
          "id": 62,
          "link": "https://disk.yandex.ru/i/M5e-NoEQNqmjlw",
          "name": "Каталог",
          "order": 3,
          "createdAt": "2023-04-21T16:24:08.000Z",
          "updatedAt": "2023-04-21T16:24:08.000Z",
          "partner_id": 22
        }, {
          "id": 63,
          "link": "https://disk.yandex.ru/i/ayMcG953jNgLkA",
          "name": "Видео",
          "order": 4,
          "createdAt": "2023-04-21T16:25:26.000Z",
          "updatedAt": "2023-04-24T12:25:27.000Z",
          "partner_id": 22
        }, {
          "id": 77,
          "link": "https://graviton.ru/",
          "name": "Ссылка на сайт",
          "order": 5,
          "createdAt": "2023-04-24T12:25:29.000Z",
          "updatedAt": "2023-04-24T12:25:29.000Z",
          "partner_id": 22
        }]
      }, {
        "id": 25,
        "name": "Код безопасности",
        "avatar": "/images/код безопасности.png",
        "link": "https://www.securitycode.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 18,
        "hideToIndex": false,
        "createdAt": "2023-04-06T07:30:01.000Z",
        "updatedAt": "2023-04-06T07:30:01.000Z",
        "PartnerFiles": [{
          "id": 13,
          "link": "https://disk.yandex.ru/i/fcHS5-KYk_RYXg",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2023-04-10T15:45:47.000Z",
          "updatedAt": "2023-04-10T15:45:47.000Z",
          "partner_id": 25
        }, {
          "id": 14,
          "link": "https://disk.yandex.ru/i/nJPUSgqzYu0O9A",
          "name": "Видео",
          "order": 2,
          "createdAt": "2023-04-10T15:46:21.000Z",
          "updatedAt": "2023-04-10T15:46:21.000Z",
          "partner_id": 25
        }]
      }, {
        "id": 26,
        "name": "AT Consulting",
        "avatar": "/images/Лига цифровой 4.png",
        "link": "https://digitalleague.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 19,
        "hideToIndex": false,
        "createdAt": "2023-04-07T13:52:35.000Z",
        "updatedAt": "2023-04-10T17:44:05.000Z",
        "PartnerFiles": [{
          "id": 64,
          "link": "https://disk.yandex.ru/i/1bhVIpT75dZKCg",
          "name": "Видео",
          "order": 1,
          "createdAt": "2023-04-21T16:29:47.000Z",
          "updatedAt": "2023-04-21T16:29:47.000Z",
          "partner_id": 26
        }]
      }, {
        "id": 47,
        "name": "Арпп",
        "avatar": "/images/АрПП.png",
        "link": "https://arppsoft.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 19,
        "hideToIndex": false,
        "createdAt": "2023-04-17T13:11:24.000Z",
        "updatedAt": "2023-04-17T13:11:24.000Z",
        "PartnerFiles": []
      }, {
        "id": 51,
        "name": "IXBT",
        "avatar": "/images/ixbt.png",
        "link": "https://www.ixbt.com/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 20,
        "hideToIndex": false,
        "createdAt": "2023-04-19T10:35:21.000Z",
        "updatedAt": "2023-04-19T10:50:48.000Z",
        "PartnerFiles": []
      }, {
        "id": 58,
        "name": "COMNEWS",
        "avatar": "/images/comnews.png",
        "link": "https://www.comnews.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 20,
        "hideToIndex": false,
        "createdAt": "2023-04-24T15:13:28.000Z",
        "updatedAt": "2023-04-24T15:20:13.000Z",
        "PartnerFiles": []
      }, {
        "id": 27,
        "name": "MasterSCADA",
        "avatar": "/images/мастерскада.png",
        "link": "https://masterscada.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 20,
        "hideToIndex": false,
        "createdAt": "2023-04-07T18:10:39.000Z",
        "updatedAt": "2023-04-07T18:11:46.000Z",
        "PartnerFiles": [{
          "id": 40,
          "link": "https://disk.yandex.ru/d/cHiBXQ2bvZXqow",
          "name": "Видео",
          "order": 1,
          "createdAt": "2023-04-21T15:42:36.000Z",
          "updatedAt": "2023-04-21T15:42:36.000Z",
          "partner_id": 27
        }]
      }, {
        "id": 30,
        "name": "ContentAI",
        "avatar": "/images/Content AI.png",
        "link": "https://contentai.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 21,
        "hideToIndex": false,
        "createdAt": "2023-04-10T13:09:03.000Z",
        "updatedAt": "2023-04-10T13:09:03.000Z",
        "PartnerFiles": [{
          "id": 65,
          "link": "https://disk.yandex.ru/i/aatBDBGC30_nGw",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2023-04-21T16:30:42.000Z",
          "updatedAt": "2023-04-21T16:30:42.000Z",
          "partner_id": 30
        }, {
          "id": 66,
          "link": "https://disk.yandex.ru/i/1kw4NIe4ywN5sA",
          "name": "Листовка 2",
          "order": 2,
          "createdAt": "2023-04-21T16:30:54.000Z",
          "updatedAt": "2023-04-21T16:30:54.000Z",
          "partner_id": 30
        }, {
          "id": 69,
          "link": "https://disk.yandex.ru/i/7FnQxZ6wPzPA8Q",
          "name": "Листовка 3",
          "order": 3,
          "createdAt": "2023-04-21T16:31:11.000Z",
          "updatedAt": "2023-04-21T16:31:11.000Z",
          "partner_id": 30
        }, {
          "id": 71,
          "link": "https://disk.yandex.ru/i/3uXu5MO9-C66jA",
          "name": "Буклет",
          "order": 4,
          "createdAt": "2023-04-21T16:32:17.000Z",
          "updatedAt": "2023-04-21T16:32:17.000Z",
          "partner_id": 30
        }, {
          "id": 72,
          "link": "https://disk.yandex.ru/i/RflFNrtvifYc9w",
          "name": "Видео",
          "order": 5,
          "createdAt": "2023-04-21T16:33:04.000Z",
          "updatedAt": "2023-04-21T16:33:04.000Z",
          "partner_id": 30
        }]
      }, {
        "id": 31,
        "name": "р7 ОФИС",
        "avatar": "/images/R7.png",
        "link": "https://r7-office.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 22,
        "hideToIndex": false,
        "createdAt": "2023-04-10T13:10:34.000Z",
        "updatedAt": "2023-04-13T15:14:23.000Z",
        "PartnerFiles": [{
          "id": 43,
          "link": "https://disk.yandex.ru/i/rIjeCvN29Qj4uQ",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2023-04-21T15:48:12.000Z",
          "updatedAt": "2023-04-21T15:48:12.000Z",
          "partner_id": 31
        }, {
          "id": 44,
          "link": "https://disk.yandex.ru/i/iW3qsg9SRtQ19g",
          "name": "Брошюра",
          "order": 2,
          "createdAt": "2023-04-21T15:48:29.000Z",
          "updatedAt": "2023-04-21T15:48:29.000Z",
          "partner_id": 31
        }, {
          "id": 45,
          "link": "https://disk.yandex.ru/i/JW-52YOUHFVNCw",
          "name": "Видео",
          "order": 3,
          "createdAt": "2023-04-21T15:48:46.000Z",
          "updatedAt": "2023-04-21T15:48:46.000Z",
          "partner_id": 31
        }]
      }, {
        "id": 33,
        "name": "Мерлион",
        "avatar": "/images/мерлион.png",
        "link": "https://merlion.com",
        "group": "Партнеры",
        "newPage": true,
        "order": 24,
        "hideToIndex": false,
        "createdAt": "2023-04-11T12:57:49.000Z",
        "updatedAt": "2023-04-11T12:59:00.000Z",
        "PartnerFiles": [{
          "id": 41,
          "link": "https://disk.yandex.ru/i/EDxJ3k0yKFHB3A",
          "name": "Информация о демостендах",
          "order": 1,
          "createdAt": "2023-04-21T15:46:10.000Z",
          "updatedAt": "2023-04-21T15:46:10.000Z",
          "partner_id": 33
        }, {
          "id": 42,
          "link": "https://disk.yandex.ru/i/NF05uPpPSM4YdA",
          "name": "Видео",
          "order": 2,
          "createdAt": "2023-04-21T15:46:17.000Z",
          "updatedAt": "2023-04-21T15:46:17.000Z",
          "partner_id": 33
        }, {
          "id": 76,
          "link": "https://rus.merlion.ru/news/polnyy-stek-resheniy-gk---astra-------v-demonstratsionno-ispytatel-noy-srede-tehtsentra-merlion",
          "name": "Полный стек решений ГК «АСТРА» — в демонстрационно-испытательной среде техцентра MERLION",
          "order": 3,
          "createdAt": "2023-04-24T12:23:01.000Z",
          "updatedAt": "2023-04-24T12:23:01.000Z",
          "partner_id": 33
        }]
      }, {
        "id": 35,
        "name": "М ифногруп",
        "avatar": "/images/инфогруп.png",
        "link": "https://m-infogroup.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 25,
        "hideToIndex": false,
        "createdAt": "2023-04-13T17:39:53.000Z",
        "updatedAt": "2023-04-13T17:39:53.000Z",
        "PartnerFiles": []
      }, {
        "id": 34,
        "name": "Газпром",
        "avatar": "/images/1_GPN_logo_rus_blue-on-white.cdr.svg",
        "link": "https://www.gazprom-neft.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 26,
        "hideToIndex": false,
        "createdAt": "2023-04-12T15:17:04.000Z",
        "updatedAt": "2023-04-12T15:17:04.000Z",
        "PartnerFiles": []
      }, {
        "id": 37,
        "name": "Сбер Интегро",
        "avatar": "/images/сберинтегро.png",
        "link": "https://sberintegro.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 27,
        "hideToIndex": false,
        "createdAt": "2023-04-13T17:49:22.000Z",
        "updatedAt": "2023-04-13T17:54:10.000Z",
        "PartnerFiles": [{
          "id": 57,
          "link": "https://disk.yandex.ru/d/mVNIbUZtFKzctw",
          "name": "Видео",
          "order": 21,
          "createdAt": "2023-04-21T16:08:13.000Z",
          "updatedAt": "2023-04-21T16:08:13.000Z",
          "partner_id": 37
        }]
      }, {
        "id": 38,
        "name": "Soflab",
        "avatar": "/images/1_GPN_logo_rus_blue-on-white.cdr (2).png",
        "link": "https://www.softlab.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 28,
        "hideToIndex": false,
        "createdAt": "2023-04-14T16:54:51.000Z",
        "updatedAt": "2023-04-14T16:57:32.000Z",
        "PartnerFiles": []
      }, {
        "id": 39,
        "name": "Biplane",
        "avatar": "/images/biplane.png",
        "link": "https://biplane24.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 29,
        "hideToIndex": false,
        "createdAt": "2023-04-14T17:01:21.000Z",
        "updatedAt": "2023-04-14T17:01:21.000Z",
        "PartnerFiles": [{
          "id": 60,
          "link": "https://disk.yandex.ru/i/fFWFiZc2sKpI4w",
          "name": "Видео",
          "order": 1,
          "createdAt": "2023-04-21T16:19:58.000Z",
          "updatedAt": "2023-04-21T16:20:33.000Z",
          "partner_id": 39
        }]
      }, {
        "id": 40,
        "name": "HiTech",
        "avatar": "/images/hitech.png",
        "link": "https://hi-tech.org/",
        "group": "Партнеры",
        "newPage": true,
        "order": 30,
        "hideToIndex": false,
        "createdAt": "2023-04-14T17:03:36.000Z",
        "updatedAt": "2023-04-14T17:03:36.000Z",
        "PartnerFiles": [{
          "id": 86,
          "link": "https://disk.yandex.ru/i/D5zbAtM1FDcOXA",
          "name": "Система автоматического распознавания лиц IVA CV",
          "order": 1,
          "createdAt": "2023-04-25T05:01:33.000Z",
          "updatedAt": "2023-04-25T05:01:33.000Z",
          "partner_id": 40
        }, {
          "id": 87,
          "link": "https://disk.yandex.ru/i/PNFaWLE2yqaBag",
          "name": "Система автоматического распознавания лиц IVA ",
          "order": 2,
          "createdAt": "2023-04-25T05:02:15.000Z",
          "updatedAt": "2023-04-25T05:02:15.000Z",
          "partner_id": 40
        }]
      }, {
        "id": 41,
        "name": "s-terra",
        "avatar": "/images/sterra.png",
        "link": "https://www.s-terra.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 31,
        "hideToIndex": false,
        "createdAt": "2023-04-14T17:06:33.000Z",
        "updatedAt": "2023-04-14T17:06:33.000Z",
        "PartnerFiles": [{
          "id": 46,
          "link": "https://disk.yandex.ru/i/y8Z-euB_fQKY0w",
          "name": "Буклет",
          "order": 1,
          "createdAt": "2023-04-21T15:52:08.000Z",
          "updatedAt": "2023-04-21T15:52:08.000Z",
          "partner_id": 41
        }, {
          "id": 47,
          "link": "https://disk.yandex.ru/i/am8QA_DX2QHP2g",
          "name": "S-terra клиент",
          "order": 2,
          "createdAt": "2023-04-21T15:53:00.000Z",
          "updatedAt": "2023-04-21T15:53:00.000Z",
          "partner_id": 41
        }, {
          "id": 48,
          "link": "https://disk.yandex.ru/i/rg56xsBsOVFSeg",
          "name": "Система обнаружения вторжений",
          "order": 2,
          "createdAt": "2023-04-21T15:53:50.000Z",
          "updatedAt": "2023-04-21T15:53:50.000Z",
          "partner_id": 41
        }, {
          "id": 49,
          "link": "https://disk.yandex.ru/i/AGIJr6Wt08-bZQ",
          "name": "S-terra шлюз",
          "order": 2,
          "createdAt": "2023-04-21T15:54:46.000Z",
          "updatedAt": "2023-04-21T15:54:46.000Z",
          "partner_id": 41
        }, {
          "id": 50,
          "link": "https://disk.yandex.ru/i/mynixXvTRucenQ",
          "name": "S Terra Юнит",
          "order": 6,
          "createdAt": "2023-04-21T15:55:51.000Z",
          "updatedAt": "2023-04-21T15:55:51.000Z",
          "partner_id": 41
        }, {
          "id": 51,
          "link": "https://disk.yandex.ru/i/-dre4W0omMVvmg",
          "name": "Виртуальный шлюз",
          "order": 7,
          "createdAt": "2023-04-21T15:56:38.000Z",
          "updatedAt": "2023-04-21T15:56:38.000Z",
          "partner_id": 41
        }, {
          "id": 52,
          "link": "https://disk.yandex.ru/i/CZ_8t0rgzrLnsw",
          "name": "Видео",
          "order": 9,
          "createdAt": "2023-04-21T15:57:02.000Z",
          "updatedAt": "2023-04-21T15:57:02.000Z",
          "partner_id": 41
        }, {
          "id": 53,
          "link": "https://disk.yandex.ru/i/Saqkr6lzSdH9HQ",
          "name": "Каталог",
          "order": 8,
          "createdAt": "2023-04-21T15:57:34.000Z",
          "updatedAt": "2023-04-21T15:57:34.000Z",
          "partner_id": 41
        }, {
          "id": 54,
          "link": "https://disk.yandex.ru/i/gaUjQ_nAT15FUQ",
          "name": "Презентация",
          "order": 10,
          "createdAt": "2023-04-21T15:57:57.000Z",
          "updatedAt": "2023-04-21T15:57:57.000Z",
          "partner_id": 41
        }, {
          "id": 55,
          "link": "https://disk.yandex.ru/i/h1oxVJ9eLgpS5w",
          "name": "Шлюз DP",
          "order": 11,
          "createdAt": "2023-04-21T15:58:34.000Z",
          "updatedAt": "2023-04-21T15:58:34.000Z",
          "partner_id": 41
        }, {
          "id": 83,
          "link": "https://disk.yandex.ru/i/hUHqwa-ee7kNPg",
          "name": "Буклет_ENG ",
          "order": 12,
          "createdAt": "2023-04-24T12:54:09.000Z",
          "updatedAt": "2023-04-24T12:54:09.000Z",
          "partner_id": 41
        }]
      }, {
        "id": 48,
        "name": "Irbis",
        "avatar": "/images/Ирбис 2.png",
        "link": "https://www.irbis.su/",
        "group": "Партнеры",
        "newPage": true,
        "order": 33,
        "hideToIndex": false,
        "createdAt": "2023-04-17T14:53:22.000Z",
        "updatedAt": "2023-04-17T14:55:04.000Z",
        "PartnerFiles": [{
          "id": 84,
          "link": "https://disk.yandex.ru/i/nIsbSjBQcOCuIA",
          "name": "Видео",
          "order": 1,
          "createdAt": "2023-04-25T04:59:14.000Z",
          "updatedAt": "2023-04-25T04:59:14.000Z",
          "partner_id": 48
        }, {
          "id": 85,
          "link": "https://disk.yandex.ru/i/I9glg0jxh1c4mg",
          "name": "Видео 2",
          "order": 2,
          "createdAt": "2023-04-25T04:59:30.000Z",
          "updatedAt": "2023-04-25T04:59:30.000Z",
          "partner_id": 48
        }]
      }, {
        "id": 49,
        "name": "ВК КЛАУД",
        "avatar": "/images/Вк клауд.png",
        "link": "https://mcs.mail.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 34,
        "hideToIndex": false,
        "createdAt": "2023-04-17T14:58:41.000Z",
        "updatedAt": "2023-04-17T14:58:41.000Z",
        "PartnerFiles": []
      }, {
        "id": 50,
        "name": "ИВА",
        "avatar": "/images/Ива2.png",
        "link": "https://iva-tech.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 35,
        "hideToIndex": false,
        "createdAt": "2023-04-17T14:59:18.000Z",
        "updatedAt": "2023-04-21T14:36:23.000Z",
        "PartnerFiles": [{
          "id": 24,
          "link": "https://disk.yandex.ru/i/F5r7f-b46vCRpA",
          "name": "Буклет",
          "order": 1,
          "createdAt": "2023-04-21T15:18:47.000Z",
          "updatedAt": "2023-04-21T15:18:47.000Z",
          "partner_id": 50
        }, {
          "id": 25,
          "link": "https://disk.yandex.ru/i/4m6uHw0qKB-AHg",
          "name": "Листовка",
          "order": 2,
          "createdAt": "2023-04-21T15:19:09.000Z",
          "updatedAt": "2023-04-21T15:19:09.000Z",
          "partner_id": 50
        }, {
          "id": 39,
          "link": "https://disk.yandex.ru/i/Z2FhwO0qkRLPcg",
          "name": "Презентация",
          "order": 3,
          "createdAt": "2023-04-21T15:39:42.000Z",
          "updatedAt": "2023-04-21T15:39:42.000Z",
          "partner_id": 50
        }]
      }, {
        "id": 52,
        "name": "инферит",
        "avatar": "/images/инферит.png",
        "link": "https://inferit.com/",
        "group": "Партнеры",
        "newPage": true,
        "order": 36,
        "hideToIndex": false,
        "createdAt": "2023-04-20T08:03:15.000Z",
        "updatedAt": "2023-04-21T14:36:35.000Z",
        "PartnerFiles": [{
          "id": 37,
          "link": "https://disk.yandex.ru/i/e0jt_-MM98evsA",
          "name": "Видео",
          "order": 1,
          "createdAt": "2023-04-21T15:36:47.000Z",
          "updatedAt": "2023-04-21T15:36:47.000Z",
          "partner_id": 52
        }, {
          "id": 38,
          "link": "https://disk.yandex.ru/i/XAzmVpPxUm8VGg",
          "name": "Каталог продуктов",
          "order": 2,
          "createdAt": "2023-04-21T15:37:09.000Z",
          "updatedAt": "2023-04-21T15:37:09.000Z",
          "partner_id": 52
        }]
      }, {
        "id": 54,
        "name": "МойОфис",
        "avatar": "/images/мойофис.png",
        "link": "https://myoffice.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 37,
        "hideToIndex": false,
        "createdAt": "2023-04-21T13:07:06.000Z",
        "updatedAt": "2023-04-24T12:48:00.000Z",
        "PartnerFiles": [{
          "id": 56,
          "link": "https://disk.yandex.ru/i/5lOuVZLhDaEU7w",
          "name": "Видео",
          "order": 20,
          "createdAt": "2023-04-21T16:05:18.000Z",
          "updatedAt": "2023-04-21T16:05:18.000Z",
          "partner_id": 54
        }, {
          "id": 88,
          "link": "https://disk.yandex.ru/i/nzWxpxxuBytquw",
          "name": "Презентация",
          "order": 2,
          "createdAt": "2023-04-25T05:18:17.000Z",
          "updatedAt": "2023-04-25T05:18:17.000Z",
          "partner_id": 54
        }, {
          "id": 89,
          "link": "https://disk.yandex.ru/i/67MeT3mFawBSyQ",
          "name": "Листовка",
          "order": 3,
          "createdAt": "2023-04-25T05:18:46.000Z",
          "updatedAt": "2023-04-25T05:18:46.000Z",
          "partner_id": 54
        }, {
          "id": 90,
          "link": "https://disk.yandex.ru/i/qQ4IPlSIeuK1zQ",
          "name": "О продукте",
          "order": 4,
          "createdAt": "2023-04-25T05:19:13.000Z",
          "updatedAt": "2023-04-25T05:19:13.000Z",
          "partner_id": 54
        }, {
          "id": 91,
          "link": "https://disk.yandex.ru/i/ilxmaoMsFw8UNQ",
          "name": "Предложение для регионов",
          "order": 5,
          "createdAt": "2023-04-25T05:19:48.000Z",
          "updatedAt": "2023-04-25T05:19:48.000Z",
          "partner_id": 54
        }, {
          "id": 92,
          "link": "https://disk.yandex.ru/i/xkLRJe1cfr04Rw",
          "name": "Программа для образования",
          "order": 6,
          "createdAt": "2023-04-25T05:20:19.000Z",
          "updatedAt": "2023-04-25T05:20:19.000Z",
          "partner_id": 54
        }, {
          "id": 93,
          "link": "https://disk.yandex.ru/i/Kb6JzjlhzhIRpQ",
          "name": "Видео",
          "order": 6,
          "createdAt": "2023-04-25T05:20:35.000Z",
          "updatedAt": "2023-04-25T05:20:35.000Z",
          "partner_id": 54
        }]
      }, {
        "id": 55,
        "name": "DCImanager",
        "avatar": "/images/dci.png",
        "link": "https://www.ispsystem.ru/dcimanager",
        "group": "Партнеры",
        "newPage": false,
        "order": 38,
        "hideToIndex": false,
        "createdAt": "2023-04-21T14:32:35.000Z",
        "updatedAt": "2023-04-21T14:36:59.000Z",
        "PartnerFiles": []
      }, {
        "id": 56,
        "name": "VMmanager",
        "avatar": "/images/vm.png",
        "link": "https://www.ispsystem.ru/vmmanager",
        "group": "Партнеры",
        "newPage": false,
        "order": 39,
        "hideToIndex": false,
        "createdAt": "2023-04-21T14:50:22.000Z",
        "updatedAt": "2023-04-21T14:50:22.000Z",
        "PartnerFiles": []
      }, {
        "id": 57,
        "name": "Awada",
        "avatar": "/images/Авада.png",
        "link": "http://awada.ru",
        "group": "Партнеры",
        "newPage": true,
        "order": 40,
        "hideToIndex": false,
        "createdAt": "2023-04-21T15:05:00.000Z",
        "updatedAt": "2023-04-21T15:05:00.000Z",
        "PartnerFiles": [{
          "id": 23,
          "link": "https://disk.yandex.ru/i/-5-n6bGKgmhczA",
          "name": "Брошюра",
          "order": 1,
          "createdAt": "2023-04-21T15:16:00.000Z",
          "updatedAt": "2023-04-21T15:16:00.000Z",
          "partner_id": 57
        }]
      }]
    }
  },
  computed: {
    getPartners() {
      if (!this.partners.length) {
        return this.partners
      }
      return _.sortBy(_.filter(this.partners, item => {
        item.PartnerFiles = _.sortBy(item.PartnerFiles, ['order'])
        return item.PartnerFiles.length
      }), ['order'])
    }
  },
  // mounted() {
  //   this.$axios.get('partner-all')
  //     .then(resp => {
  //       this.partners = resp.data
  //     })
  //     .catch(e => {
  //       console.log(e)
  //     })
  // }
}
</script>

<style scoped lang="scss">
.section_votes {
  padding: rem(30) 0;
}

.partner {
  background: #FFFFFF;
  width: 100%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: rem(60) rem(30) rem(24);
  @include media(768) {
    padding: rem(15);
  }

  &__image {
    //padding: 0 rem(20);
    text-align: center;

    img {
      max-width: 100%;
      vertical-align: top;
    }
  }

  &__buttons {
    padding-top: rem(20);
  }

  &__button {
    cursor: pointer;

    svg {
      * {
        transition: all .25s;
      }
    }

    &:hover {
      svg {
        circle {
          fill: #59B980
        }
      }
    }

    &:first-child {
      margin-right: rem(5);
    }
  }

  &__file {
    padding-right: rem(18);

    a {
      font-size: rem(12);
      color: #52B5E0;
      text-decoration: none;
    }
  }
}
</style>
