import { render, staticRenderFns } from "./Materials.vue?vue&type=template&id=5eb19e9b&scoped=true&"
import script from "./Materials.vue?vue&type=script&lang=js&"
export * from "./Materials.vue?vue&type=script&lang=js&"
import style0 from "./Materials.vue?vue&type=style&index=0&id=5eb19e9b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eb19e9b",
  null
  
)

export default component.exports